import { useEffect, useRef, useState } from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import { VANPOOL_INFO_LOADING, SUBSCRIPTION_SUMMARY_LOADING } from '../../redux/actionTypes';
import ArrowLeft from '../../_styles/images/ArrowLeft.png';
import PaymentCard from '../common/paymentCard';
import { getSortedCards } from '../helper';
import SubscriptionPlan from '../paymentOverview/SubscriptionPlan';
import ContactPopup from '../../shared/components/contact-popup/ContactPopup';
import PriceDisclaimerPopup from '../../join-commute/subscription/PriceDisclaimerPopup';
import TaxesAccordion from '../common/TaxesAccordion';
import RateAccordion from '../common/RateAccordion';
import useError from '../../shared/components/error-screen/useError';
import { numberWithCommas } from '../../join-commute/helpers/commonHelper';
import analyticsService from '../../shared/analytics/analytics-service';
import { getFullPrice } from '../../join-commute/helpers/vanHelpers';
import { assignPriceToCards } from '../../shared/helpers/cardHelpers';
import { FULL_MONTHS } from '../../shared/inputs/Calendar/Calendar';


function SubscriptionPlanDetail(props) {

  const oneTimeChargesPopupNode = useRef(null);
  const [loading, updateLoading] = useState(false);
  const [dateStr, updateDateStr] = useState('');
  const [sortedCards, updateSortedCards] = useState([]);
  const [cards, updateCards] = useState([]);
  const [total, updateTotal] = useState({});
  const [totalAmount, updateTotalAmount] = useState(0);
  const [showOneTimeChargePopup, updateShowOneTimeChargePopup] = useState(false);
  const [showPDPopup, updatePDPopup] = useState(false);
  const [isDriver, updateIsDriver] = useState(false);
  const [subscriptionRate, updateSubscriptionRate] = useState(0);
  const [taxes, updateTaxes] = useState([]);
  const [surcharges, updateSurcharges] = useState([]);
  const [surchargeAmount, updateSurchargeAmount] = useState();
  const [taxAmount, updateTaxamount] = useState();

  useOnClickOutside(oneTimeChargesPopupNode, () => updateShowOneTimeChargePopup(false));

  useEffect(() => {
    updateLoading(true);
    props.init();
    if (!props.vanpoolInfoLoaded) {
      props.loadVanpoolInfo();
    }
    analyticsService.pageInfo("payment/subscription plan");
  }, []);

  useEffect(() => {
    if (props.loaded) {
      updateLoading(false);
    }
  }, [props.loaded])

  useError(props.summaryLoadError, props.history, props.summaryLoadErrorString);

  useEffect(() => {
    if (props.subscriptionSummary) {
      if (props.subscriptionSummary.invoiceSummary) {
        if (props.subscriptionSummary.invoiceSummary.invoiceMonth && props.subscriptionSummary.invoiceSummary.invoiceYear) {
          const monthIndex = parseInt(props.subscriptionSummary.invoiceSummary.invoiceMonth) - 1;
          if(monthIndex){
            updateDateStr(`${FULL_MONTHS[monthIndex]} ${props.subscriptionSummary.invoiceSummary.invoiceYear}`);
          }
        }
      }
      if (props.subscriptionSummary.storedPaymentCards) {
        const cards = getSortedCards(props.subscriptionSummary.storedPaymentCards);
        updateSortedCards(cards);
      }
    }
    let price = {
      price: '0',
      decimal: '00'
    };
    if (props.profile && props.profile.subscriptionAPIInfo && props.profile.subscriptionAPIInfo.package && props.profile.subscriptionAPIInfo.totalAmount) {
      updateTotalAmount(props.profile.subscriptionAPIInfo.totalAmount);
      price = getFullPrice(props.profile.subscriptionAPIInfo.totalAmount);
      updateIsDriver(props.profile.subscriptionAPIInfo.isDriverDiscount);
      updateSubscriptionRate(props.profile.subscriptionAPIInfo.subscriptionRate);
      updateSurchargeAmount(props.profile.subscriptionAPIInfo.surchargeAmount);
      updateSurcharges(props.profile.subscriptionAPIInfo.surcharges);
      updateTaxamount(props.profile.subscriptionAPIInfo.taxAmount);
      updateTaxes(props.profile.subscriptionAPIInfo.taxes);
    } else if (props.profile && props.profile.subscriptionAPIInfo && props.profile.subscriptionAPIInfo.pendingChange && props.profile.subscriptionAPIInfo.pendingChange.package && props.profile.subscriptionAPIInfo.pendingChange.totalAmount) {
      price = getFullPrice(props.profile.subscriptionAPIInfo.pendingChange.totalAmount);
      updateTotalAmount(props.profile.subscriptionAPIInfo.pendingChange.totalAmount);
      updateIsDriver(props.profile.subscriptionAPIInfo.pendingChange.isDriverDiscount);
      updateSubscriptionRate(props.profile.subscriptionAPIInfo.pendingChange.subscriptionRate);
      updateSurchargeAmount(props.profile.subscriptionAPIInfo.pendingChange.surchargeAmount);
      updateSurcharges(props.profile.subscriptionAPIInfo.pendingChange.surcharges);
      updateTaxamount(props.profile.subscriptionAPIInfo.pendingChange.taxAmount);
      updateTaxes(props.profile.subscriptionAPIInfo.pendingChange.taxes);
    }
    updateTotal(price);
  }, [props.subscriptionSummary, props.profile]);

  //used to perform closing of popups on outside clicks
  function useOnClickOutside(ref, handler) {
    useEffect(
      () => {
        const listener = (event) => {
          if (!ref.current || ref.current.contains(event.target)) {
            return;
          }
          handler(event);
        };
        document.addEventListener("mousedown", listener);
        document.addEventListener("touchstart", listener);
        return () => {
          document.removeEventListener("mousedown", listener);
          document.removeEventListener("touchstart", listener);
        };
      },
      [ref, handler]
    );
  }

  useEffect(() => {
    const close = (e) => {
      if (e.keyCode === 27) {
        const popup = document.getElementById('one-time-charges-popup');
        if (popup) {
          updateShowOneTimeChargePopup(false);
        }
      }
    }
    window.addEventListener('keydown', close)
    return () => window.removeEventListener('keydown', close)
  }, [])

  // function oneTimeChargesPopupKeydown(e) {
  //   if (e.which === 13) {
  //     updateShowOneTimeChargePopup(true);
  //   }
  // }

  // useEffect(() => {
  //   if(sortedCards){
  //     const card = sortedCards[0];
  //     let chargeCards = [];
  //     if(card && card.isFederalBenefitCard) {
  //       const maxAmout = parseFloat(card.maxAuthorizedAmount);
  //       if(onetimeChargeTotal > maxAmout){
  //         chargeCards.push({...card, chargeAmount: maxAmout});
  //         chargeCards.push({...sortedCards[1], chargeAmount: (onetimeChargeTotal-maxAmout)});
  //       } else {
  //         chargeCards.push({...card, chargeAmount: onetimeChargeTotal});
  //       }
  //     } else {
  //       chargeCards.push({...card, chargeAmount: onetimeChargeTotal});
  //     }
  //     updateOnetimeChargeCards(chargeCards);
  //   }
  // }, [onetimeChargeTotal, sortedCards])

  useEffect(() => {
    if(sortedCards.length > 0 && totalAmount){
      const price = parseFloat(totalAmount);
      const chargedCards = assignPriceToCards(price, sortedCards);
      updateCards(chargedCards);
    }
  }, [sortedCards, totalAmount])

  return (
    <div className='subscription-detail'>
      <div className='top-header'>
        <div className='backArrow'>
          <Link className="overview-link" onClick={props.history.goBack}>
            <img src={ArrowLeft} alt='go back'></img>
          </Link>
        </div>
        <div className='heading'>Subscription Plan</div>
      </div>
      <div className='subscription-detail-page'>
        {
          loading ? <div className="loader"></div> :
            <div className='content'>
              <div className='top-content' >
                <SubscriptionPlan
                subscription={props.subscriptionSummary}
                vanpoolInfo={props.vanpoolInfo}
                profile={props.profile}
                subscriptionAPIInfo={props.subscriptionAPIInfo} />
              </div>
              <div className='bottom-content'>
                <div className='left-content-section'>
                  <div className='charges-container' role='group'>
                    <div className='sub-title'>Monthly charges</div>
                    <div className='month'>{dateStr}</div>
                    <div className='charges'>
                      <RateAccordion
                        isDesiredDriverRole={isDriver}
                        rateMessage={"Due to fluctuations in fuel prices, your rate can change to cover the additional costs."}
                        charge={subscriptionRate}
                      />
                      <TaxesAccordion total={parseFloat(surchargeAmount) + parseFloat(taxAmount)} taxes={taxes} surcharges={surcharges} />
                      {/* {props.subscriptionSummary && props.subscriptionSummary.oneTimeCharges && props.subscriptionSummary.oneTimeCharges.length > 0 && <OneTimeChargesAccordion oneTimeCharges={props.subscriptionSummary && props.subscriptionSummary.oneTimeCharges} onKeyDown={oneTimeChargesPopupKeydown} onClick={() => updateShowOneTimeChargePopup(true)} />} */}
                    </div>
                  </div>
                  <div className='bottom' role='group'>
                    <div className='disclaimer' role='group'>
                    <div className='label'>Subscription Plan</div>
                      <p>
                        Recurring subscription. <br />
                        Rates subject to change.&nbsp;
                        <span onClick={() => updatePDPopup(true)}>Learn More</span>
                      </p>
                    </div>
                    <div className='total' role='group'>
                      <span className='grp'>
                        <div className='total-price' aria-label={`${total.price} dollars and ${total.decimal} cents total per month`}>
                          <div className='dollar'>$</div>
                          <div className='price'>{total.price && numberWithCommas(total.price) || '0'}</div>
                          <div className='decimal'>.{total.decimal || '00'}</div>
                        </div>
                        <div className='frequency'>total per month</div>
                      </span>
                    </div>
                  </div>
                </div>
                <div className='right-content-section' role='group'>
                  <div className='sub-title'>Payment Cards</div>
                  <div className='subscription-charges-container'>
                    <div className='card-charges'>Subscription Charges</div>
                    {
                      cards && cards.map((card) => <PaymentCard key={card.partialAccountNumber} card={card} />)
                    }
                  </div>
                </div>
              </div>
            </div>
        }
      </div>
      {
        showPDPopup && <PriceDisclaimerPopup updatePDPopup={() => updatePDPopup(false)} />
      }
      {showOneTimeChargePopup && <ContactPopup title='Your total includes a One-time Charge' firstText='Typical one-time charges include lost key fob charges, toll charges, etc.' contactText='The one-time charge will be applied to your default card and any overages will be applied to your backup card if applicable. If you have any questions, please call' reference={oneTimeChargesPopupNode} close={() => updateShowOneTimeChargePopup(false)} />}
    </div>
  )
}

function mapStateToProps(state) {
  return {
    profile: state.profile,
    subscriptionSummary: state.PaymentOverview.subscriptionSummary,
    summaryLoadError: state.PaymentOverview.loadError,
    summaryLoadErrorString: state.PaymentOverview.loadErrorString,
    loaded: state.PaymentOverview.summaryLoaded,
    vanpoolInfo: state.vanpoolInfo,
    vanpoolInfoLoaded: state.vanpoolInfo.loaded,
    paymentHistory: state.paymentHistory.rawPaymentHistory && state.paymentHistory.rawPaymentHistory.transactionGroup,
    joinCommuteData: state.joinCommute,
    totalAmount: state.profile && state.profile.subscriptionAPIInfo && state.profile.subscriptionAPIInfo.totalAmount,
    subscriptionAPIInfo: state.profile.subscriptionAPIInfo
  }
}

function mapDispatchToProps(dispatch) {
  return {
    init() {
      dispatch({ type: SUBSCRIPTION_SUMMARY_LOADING });
    },
    loadVanpoolInfo() {
      dispatch({ type: VANPOOL_INFO_LOADING });
    }
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(SubscriptionPlanDetail);